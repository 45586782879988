import api from '@/apis/api'
/**
 * 供应商用户相关api
 */
export class SupplierApi extends api {


  setState (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/setSupplierStatus`,
      method: 'put',
      data: form
    })
  }

  changePassword (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/changeAccountPassword`,
      method: 'post',
      data: form
    })
  }
  resetPassword (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/resetPassword`,
      method: 'post',
      data: form
    })
  }
  resetAccountPasswordBatch(condition){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/resetAccountPasswordBatch`,
      method: 'post',
      data: condition
    })
  }
  resetStatusBatch (condition) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/setSupplierStatusBatch`,
      method: 'put',
      data: condition
    })
  }
}

export default new SupplierApi('supplier')
